<div class="container">
    <div style="height: 100vh;">
        <div class="row" style="height: 100vh;">
            <div class="col-3 bg-secondary">
                <h4 class="text-light text-center">
                    <strong>Usuarios disponibles</strong>
                </h4>
                <hr>
                <div class="d-flex flex-row bd-highlight mb-3" style="cursor: pointer;">
                    <div class="mx-1 d-flex align-items-center">
                        <i class="fas fa-user fa-2x bg-dark text-light rounded-circle p-2"></i>
                    </div>
                    <div class="mx-1 d-flex align-items-center">
                        <p class="text-light">Usuario Mobile</p>                        
                    </div>
                </div>
                <hr>
            </div>

            <div class="col-9 bg-light d-flex flex-column">
                <div style="max-height: 90vh; overflow-y: auto;">
                    <div *ngFor="let msg of mensajes">
                        <div *ngIf="msg.origen===ORIGEN_MSG.MOBILE" class="alert msg-box-mobile" role="alert">
                            <span [innerHTML]="br2nl(msg.mensaje)"></span>
                        </div>
                        <div *ngIf="msg.origen===ORIGEN_MSG.WEB" class="alert msg-box-web" role="alert">
                            <span [innerHTML]="br2nl(msg.mensaje)"></span>
                        </div>
                    </div>
                </div> 
                <div class="mt-auto bg-white mb-2">
                    <div class="d-flex bd-highlight">
                        <div class="p-2 w-100 bd-highlight">
                            <textarea 
                            name="message" cols="10" class="form-control" [value]="inputMsg" (input)="inputMsg = $event.target.value;"></textarea>
                        </div>
                        <div class="p-2 flex-shrink-1 bd-highlight align-self-center">
                            <button class="btn btn-primary" (click)="enviar()">
                                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                            </button>
                        </div>
                      </div>

                </div>
            </div> 
        </div>
    </div>
</div>