import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageInterface } from '../../interfaces/message.interface';
import { EventMqttService } from '../../services/event-mqtt.service';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.css']
})
export class ChatListComponent implements OnInit {

  ORIGEN_MSG = {
    MOBILE: 'MOBILE',
    WEB: 'WEB'
  }

  TOPICS = {
    PUBLISH:'hila_app_mobile_subscribe',
    SUBSCRIBE: 'hila_app_mobile_publish'
  }

  inputMsg: string = '';

  mensajes: MessageInterface[];

  //events: any[];
  private deviceId: string;
  subscription: Subscription;

  constructor(
    private readonly eventMqtt: EventMqttService,
  ) {
  }

  ngOnInit(): void {
    this.cargar();

    this.subscribeToTopic();

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }



  private subscribeToTopic() {
    this.subscription = this.eventMqtt.topic(this.TOPICS.SUBSCRIBE)
      .subscribe((data) => {
        let msg:MessageInterface = {
          time: (new Date()).getTime().toString(),
          origen: this.ORIGEN_MSG.MOBILE,
          mensaje: data.payload.toString()        
        }

        this.mensajes.push(msg);
      }, (error)=>{
        console.warn('error', error)
      });            
  }

  br2nl = (texto: string) => { return texto.split("\n").join("<br />");}

  enviar = async () => {
    if (this.inputMsg.trim() === '') return;

    await this.eventMqtt.publish(this.TOPICS.PUBLISH, this.inputMsg);
    
    let msg: MessageInterface = {
      time: (new Date()).getTime().toString(),
      origen: 'WEB',
      mensaje: this.inputMsg
    }
    await this.setNewMsg(msg);
    this.inputMsg = '';
  }

  cargar = async () => {
    this.mensajes = [];
    // this.mensajes = [
    //   {
    //     time:'1',
    //     origen: 'WEB',
    //     mensaje:'Hola usuario mobile'
    //   },
    //   {
    //     time:'2',
    //     origen: 'MOBILE',
    //     mensaje:'Hola usuario web'
    //   },
    //   {
    //     time:'3',
    //     origen: 'MOBILE',
    //     mensaje:'Necesito ayuda'
    //   },
    //   {
    //     time:'4',
    //     origen: 'WEB',
    //     mensaje:'Con todo gusto'
    //   },
    //   {
    //     time:'5',
    //     origen: 'WEB',
    //     mensaje:'Digame, ¿En qué puedo ayudarle?'
    //   },
    //   {
    //     time:'6',
    //     origen: 'WEB',
    //     mensaje:'Digame, ¿En qué puedo ayudarle?'
    //   },
    //   {
    //     time:'7',
    //     origen: 'WEB',
    //     mensaje:'Digame, ¿En qué puedo ayudarle?'
    //   },
    //   {
    //     time:'8',
    //     origen: 'WEB',
    //     mensaje:'Digame, ¿En qué puedo ayudarle?'
    //   },
    //   {
    //     time:'9',
    //     origen: 'WEB',
    //     mensaje:'Digame, ¿En qué puedo ayudarle?'
    //   },
    //   {
    //     time:'10',
    //     origen: 'WEB',
    //     mensaje:'Digame, ¿En qué puedo ayudarle?'
    //   },
    //   {
    //     time:'11',
    //     origen: 'MOBILE',
    //     mensaje:'Necesito ayuda'
    //   },
    //   {
    //     time:'12',
    //     origen: 'MOBILE',
    //     mensaje:'Necesito ayuda'
    //   },
    //   {
    //     time:'13',
    //     origen: 'MOBILE',
    //     mensaje:'Necesito ayuda'
    //   },
    //   {
    //     time:'14',
    //     origen: 'MOBILE',
    //     mensaje:'Necesito ayuda'
    //   },
    //   {
    //     time:'15',
    //     origen: 'MOBILE',
    //     mensaje:'Necesito ayuda'
    //   },
    // ];
  }

  setNewMsg = async (msg: MessageInterface) => {
    this.mensajes.push(msg);
  }

}
