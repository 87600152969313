import { Injectable } from '@angular/core';
import { MqttService } from 'ngx-mqtt';

@Injectable({
  providedIn: 'root'
})
export class EventMqttService {
  constructor(private _mqttService: MqttService) { }

  topic = (deviceId: string) => {
    let topicName = `${deviceId}`;
    return this._mqttService.observe(topicName);
  }

  publish = async(topic: string, message: string) => {
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: false });
  }
}
